import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import BookingDetails from '../BookingDetails/BookingDetails';
import { SidebarViewModel } from '../../ViewModel/sidebarViewModel/sidebarViewModel';
import { FlowElements, useStateFlow } from '../../Hooks/useFlow';
import Login from '../Login/Login';
import { classes, st } from './Sidebar.st.css';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { Summary } from '../Summary/Summary';
import { Button } from '../Button/Button';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { SidebarDataHooks } from './constants';

export interface SidebarProps {
  viewModel: SidebarViewModel;
}

function isSidebarBelowBody(sidebar: HTMLDivElement | undefined) {
  const body = sidebar?.previousSibling as HTMLElement;
  return sidebar?.offsetLeft === body?.offsetLeft;
}

const Sidebar: React.FC<SidebarProps> = ({
  viewModel: {
    title,
    bookingDetailsViewModel,
    loginViewModel,
    ctaViewModel,
    summaryViewModel,
  },
}) => {
  const { onNext } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const [sidebar, setSidebar] = useStateFlow(FlowElements.SIDEBAR);
  const shouldBeCollapsed = isSidebarBelowBody(sidebar);
  const { experiments } = useExperiments();
  const isCollapseServiceDetailsEnabled = experiments.enabled(
    'specs.bookings.calendar.collapseServiceDetails',
  );
  const showTitle = !isCollapseServiceDetailsEnabled || !!summaryViewModel;

  return (
    <div
      ref={(ref) => setSidebar(ref!)}
      data-hook={SidebarDataHooks.ROOT}
      className={st(classes.root, { isMobile })}
    >
      {showTitle && <SectionHeader title={title} />}
      {!!bookingDetailsViewModel && (
        <BookingDetails
          viewModel={bookingDetailsViewModel}
          title={title}
          isCollapsed={shouldBeCollapsed}
        />
      )}
      {!!summaryViewModel && <Summary viewModel={summaryViewModel} />}
      <Button
        disabled={ctaViewModel.disabled}
        data-hook={SidebarDataHooks.CTA}
        className={classes.cta}
        fullWidth={ctaViewModel.fullWidth}
        loading={ctaViewModel.loading}
        onClick={() => onNext()}
      >
        {ctaViewModel.label}
      </Button>
      <Login viewModel={loginViewModel} />
    </div>
  );
};

export default Sidebar;
